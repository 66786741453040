<template>
  <div id="sideop-main">
    <v-container fluid  class="mb-10">
      <v-row
      align="center"
      justify="center">
        <v-col sm="12" md="12" lg="6" xl="6" >
            <v-container fluid >
                <v-row
                align="center"
                justify="center">
                    <v-col cols="12"
                    class="mt-15 lighten-2 fill-height
                    d-flex flex-column justify-center align-center">
                        <div flat tile
                        v-for="(item,i) in productos"
                        :key="i">
                            <card-item-info
                            :item="item">
                            </card-item-info>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col sm="12" md="12" lg="6" xl="6">
          <v-container fluid  >
            <v-row align="center" justify="center">
              <v-col
              height="100%"
              sm="12" md="12" lg="6" xl="6"
              v-for="(item,i) in imagesProducto"
              :key="i">
                <v-img
                  class="shrink ma-2"
                  contain
                  :src="item.src"
                  style="flex-basis: 125px">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardItemInfo from '../components/elementos/CardItemInfo.vue';

export default {
  name: 'SideopMain',
  components: {
    CardItemInfo,
  },
  data() {
    return {
      imagesProducto: [
        {
          src: 'sideop.png',
          title: '',
        },
      ],
      productos: [
        {
          title: 'SIDEOP',
          subtitle: `<p>Plataforma Web para el control de los procesos relacionados 
                    con la ejecución de obras públicas</p>`,
          color: 'cardbkg ',
          image: 'sideop.png',
          description: `
           <ul>
            <li>Planeación</li>
            <li>Programación</li>
            <li>Autorización de Recursos</li>
            <li>Licitaciones</li>
            <li>Adjudicación Directa</li>
            <li>Contratación</li>
            <li>Avances Físico-Financieros</li>
            <li>Finiquito de Obra</li>
            <li>Expediente Unitario Digital</li>
            Digital
            <li>Estadísticas y Reportes</li>
        </ul>
        <br>
        <h5>Principales ventajas</h5>
        <br>
        <ul>
            <li>
            Fortalece el trabajo colaborativo de todos los responsables de las Obras
            </li>
            <li>
            Facilita el acceso a la información de las obras
            </li>
            <li>
            Evita errores en el calculo de avances, estimaciones, penas convencionales, etc.
            </li>
            <li>
            Apoya el cumplimiento de los Programas de Obra
            </li>
        </ul>
        <br><br>`,
          conthumbnail: false,
        }],
    };
  },
};
</script>
<style>

</style>
