import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

axios.interceptors.request.use((config) => config,
  (error) => Promise.reject(error));

axios.interceptors.response.use((response) => response,
  (error) => {
    // Si el servidor respondio
    if (error.response) {
      return Promise.reject(error.response);
    }
    // Si el servidor no respondio
    if (error.request) {
      return Promise.reject(error.request);
    }

    // Algun error deconocico ocurrio
    return Promise.reject(error.message);
  });

export default Vue.axios;
