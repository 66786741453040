<template>
  <v-carousel
    cycle
    height="500"
    interval="3000"
    :hide-delimiters="true"
    :show-arrows="false">
    <v-carousel-item
    v-for="(slide, i) in slides"
    :key="i">
      <v-sheet
        :color="colors[i]"
        height="100%"
        class="pa-2">
        <v-row
        class="fill-height pa-2"
        align="center"
        justify="center">
          <div class="bkground--text ">
            <v-row>
              <v-col>
                <span class="text-h2">
                  {{ slide }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-2" sm="12" md="6">
                <span class="text-h6 justify-center font-weight-light">

                </span>
              </v-col>
            </v-row>
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'CarruselItems',
  props: {
    slides: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    images: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {

    };
  },

};
</script>
