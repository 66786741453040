<template>
    <v-card
    color="cardbkg"
    class="ma-1"
    flat
    height="100%" >
      <!-- Imagen del Cards -->
      <v-img
      :src="image">
      </v-img>
      <!-- Titulo del Card -->
      <v-card-title
      class="highlight--text  " >
        <h2 >{{title}}</h2>
      </v-card-title>
      <!-- Texto del Card -->
      <v-card-text
      class="cardtext--text align-center text-h6">
        {{textDescription}}
      </v-card-text>
    </v-card>

</template>

<script>
export default {
  name: 'CardItem',
  props: {
    image: {
      type: String,
      required: true,
      Default: 'texture.svg',
    },
    title: {
      type: String,
      required: false,
      Default: 'Title',
    },
    textDescription: {
      type: String,
      required: false,
      Default: 'Text',
    },
  },
};
</script>
