<template>
  <div id="osd-main">
    <v-container fluid class="mb-10">
      <v-row
      align="center"
      justify="center">
        <v-col sm="12" md="12" lg="6" xl="6" >
            <v-container fluid >
                <v-row
                align="center"
                justify="center">
                    <v-col cols="12"
                    class="mt-15 lighten-2 fill-height
                    d-flex flex-column justify-center align-center">
                        <div flat tile
                        v-for="(item,i) in productos"
                        :key="i">
                            <card-item-info
                            :item="item">
                            </card-item-info>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col sm="12" md="12" lg="6" xl="6">
          <v-container fluid  >
            <v-row align="center" justify="center">
              <v-col
              height="100%"
              sm="12" md="12" lg="6" xl="6"
              v-for="(item,i) in imagesProducto"
              :key="i">
                <v-img
                  class="shrink ma-2"
                  contain
                  :src="item.src"
                  style="flex-basis: 125px">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardItemInfo from '../components/elementos/CardItemInfo.vue';

export default {
  name: 'OsdMain',
  components: {
    CardItemInfo,
  },
  data() {
    return {
      imagesProducto: [
        {
          src: 'osd.jpg',
          title: '',
        },
      ],
      productos: [
        {
          title: 'OSD',
          subtitle: `<p>Plataforma Web para la mejora de propuestas 
          de licitaciones mediante el esquema de ofertas subsecuentes de descuento</p>`,
          color: 'cardbkg ',
          image: 'osd.jpg',
          description: `
           <ul>
                <li>Registro de Partidas de Licitación y Licitantes</li>
                <li>Controlador de temporizador para recepción de pujas o cotizaciones</li>
                <li>Cuadros Comparativos</li>
                <li>Monitor de Actividad en tiempo real</li>
                <li>Histórico de pujas o cotizaciones</li>
            </ul>
            <br>
            <h3>Principales ventajas</h3>
            <ul>
                <li>Se obtienen significativos ahorros mediante la mejora de tazas o precios</li>
                <li>Favorece la libre competencia</li>
                <li>Brinda una imagen de transparencia y apertura a la unidad compradora</li>
                
            </ul>
            <br><br>`,
          conthumbnail: false,
        }],
    };
  },
};
</script>
<style>

</style>
