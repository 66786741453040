<template>
  <div id="productos-main">
    <carrusel-items
    :slides="slides"
    :colors="colors"
    ></carrusel-items>

    <div class="md-6 lg-6 pt-10">
      <div id="cardsProductos" class="mb-10">
          <v-row>
              <v-col
              v-for="(item,i) in productos"
              :key="i"
              sm="12" md="6" lg="4" xl="4">
              <card-item-info :item="item"></card-item-info>
              </v-col>
          </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import CardItemInfo from '../components/elementos/CardItemInfo.vue';
import CarruselItems from '../components/elementos/CarruselItems.vue';

export default {
  name: 'ProductosMain',
  components: {
    CarruselItems,
    CardItemInfo,
  },
  data() {
    return {
      colors: [
        'highlight',
        'highlight',
        'highlight',
        'highlight',
        'highlight',
      ],
      slides: [
        'TuArchivo',
        'SIDEOP',
        'eCompras',
        'Propuestas',
        'OSD',
      ],
      productos: [
        {
          title: 'SIDEOP',
          subtitle: `<p>Plataforma Web para el control de los procesos relacionados 
                    con la ejecución de obras públicas</p>`,
          color: 'cardbkg ',
          image: 'sideop.png',
          link: '/sideop',
          description: '',
          conthumbnail: true,
        },
        {
          title: 'TuArchivo',
          subtitle: `<p>Plataforma Web para la integración del 
                    Archivo Digital de una organización</p>`,
          color: 'cardbkg ',
          image: 'tuarchivo.png',
          link: '/tuarchivo',
          description: '',
          conthumbnail: true,
        },
        {
          title: 'eCompras',
          subtitle: `<p>Plataforma Web para la adquisición de productos 
                y servicios en un esquema abierto 
                de participación de proveedores</p>`,
          color: 'cardbkg ',
          image: 'compras.jpg',
          link: '/ecompras',
          description: '',
          conthumbnail: true,
        },
        {
          title: 'Propuestas',
          subtitle: `<p>Portal para la participación ciudadana 
          mediante propuestas y opiniones para 
          la integración de planes y programas de gobierno</p>`,
          color: 'cardbkg ',
          image: 'propuestas.jpg',
          link: '/propuestas',
          description: '',
          conthumbnail: true,
        },
        {
          title: 'OSD',
          subtitle: `<p>Plataforma Web para la mejora de propuestas 
          de licitaciones mediante el esquema de ofertas subsecuentes de descuento</p>`,
          color: 'cardbkg ',
          image: 'osd.jpg',
          link: '/osd',
          description: '',
          conthumbnail: true,
        },
      ],
    };
  },
};
</script>
<style>
p{
    word-break: normal;
}
</style>
