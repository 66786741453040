<template>
  <div id="propuestas-main">
    <v-container fluid class="mb-10">
      <v-row
      align="center"
      justify="center">
        <v-col sm="12" md="12" lg="6" xl="6" >
            <v-container fluid >
                <v-row
                align="center"
                justify="center">
                    <v-col cols="12"
                    class="mt-15 lighten-2 fill-height
                    d-flex flex-column justify-center align-center">
                        <div flat tile
                        v-for="(item,i) in productos"
                        :key="i">
                            <card-item-info
                            :item="item">
                            </card-item-info>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col sm="12" md="12" lg="6" xl="6">
          <v-container fluid  >
            <v-row align="center" justify="center">
              <v-col
              height="100%"
              sm="12" md="12" lg="6" xl="6"
              v-for="(item,i) in imagesProducto"
              :key="i">
                <v-img
                  class="shrink ma-2"
                  contain
                  :src="item.src"
                  style="flex-basis: 125px">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardItemInfo from '../components/elementos/CardItemInfo.vue';

export default {
  name: 'PropuestasMain',
  components: {
    CardItemInfo,
  },
  data() {
    return {
      imagesProducto: [
        {
          src: 'propuestas.jpg',
          title: '',
        },
      ],
      productos: [
        {
          title: 'Propuestas',
          subtitle: `<p>Portal para la participación ciudadana 
          mediante propuestas y opiniones para 
          la integración de planes y programas de gobierno</p>`,
          color: 'cardbkg ',
          image: 'propuestas.jpg',
          description: `
           <ul>
                <li>Registro de Ciudadanos</li>
                <li>Registro de propuestas</li>
                <li>Clasificación de propuestas por Temas y Subtemas </li>
                <li>Reportes y Estadísticas</li>
            </ul>
            <br>
            <h3>Principales ventajas</h3>
            <ul>
                <li>Ayuda a integrar planes y programas basados en las
                necesidades reales de la población</li>
                <li>Facilita la participación</li>
            </ul>
            <br><br>`,
          conthumbnail: false,
        }],
    };
  },
};
</script>
<style>

</style>
