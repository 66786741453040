<template>
<div id="menu-line">
    <!-- Toolbar -->
    <v-toolbar
    flat
    color="barmenu"
    height="100px"
    dense>
      <v-toolbar-title id="toolbarmain">
        <!-- Ruta del home en el logo -->
        <router-link to="/">
          <v-img
            alt="ISNC"
            class="shrink mr-2"
            contain
            src="../../public/isnc-logo.png"
            transition="scale-transition"
            width="150px"
            to="/productos" />
        </router-link>
        </v-toolbar-title>
        <v-toolbar-title class="mx-0" ></v-toolbar-title>
        <v-spacer></v-spacer>
        <!--Opciones del menu -->
        <v-toolbar-items>
          <v-btn v-for="item in options" :key="item.id" color="barmenu"
          class="cardbkg--text text-sutitle-1 font-weight-bold" >
            <router-link
            :to="item.link"
            class="text-h6 font-weight-bold"
            style="text-decoration: none; color: inherit; ">
              {{item.name}}
            </router-link>
          </v-btn>
        </v-toolbar-items>
    </v-toolbar>
    <!-- Banner de noticias -->
    <banner-noticia
    :mensaje="noticia.menssage"
    :icon="noticia.icon">
    </banner-noticia>

</div>
</template>

<script>
import BannerNoticia from './BannerNoticia.vue';

export default {
  name: 'MenuLine',
  components: {
    BannerNoticia,
  },
  data() {
    return {
      noticia: {
        menssage: 'Conoce SIDEOP v2.0, la mejor herramienta para el control de obra pública',
        icon: 'mdi-check-decagram-outline',
      },
      options: [
        {
          id: 'productos',
          name: 'Productos',
          link: '/productos',
          icon: 'mdi-apps',
        },
        {
          id: 'servicios',
          name: 'Servicios',
          link: '/servicios',
          icon: 'mdi-progress-wrench',
        },
        {
          id: 'consultoria',
          name: 'Consultoría',
          link: '/consultoria',
          icon: 'mdi-gesture-tap-hold',
        },

      ],
    };
  },
};
</script>

<style lang="scss">

</style>
