<template>
    <v-banner
      app
      :sticky="sticky"
      :color="colorBanner"
      class="pa-0"
      height="80px"
      >
      <v-avatar
      slot="icon"
      :color="colorAvatar"
      >
        <v-icon
        large
        :color="colorIcon" >
          {{icon}}
        </v-icon>
      </v-avatar>
      <p class="white--text subtitle-1 font-weight-light">
        {{mensaje}}
      </p>
    </v-banner>
</template>

<script>
export default {
  name: 'BannerNoticia',
  props: {
    mensaje: {
      type: String,
      required: false,
      default: '',
    },
    sticky: {
      type: String,
      required: false,
      default: 'sticky',
    },
    colorBanner: {
      type: String,
      required: false,
      default: 'noticiabkg',
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi-pin',
    },
    colorIcon: {
      type: String,
      required: false,
      default: 'white',
    },
    colorAvatar: {
      type: String,
      required: false,
      default: 'bkgSecundary',
    },
  },
  data() {
    return {

    };
  },
};
</script>
