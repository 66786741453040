<template>
    <v-card
    flat
    class="elevation-0"
    :color="item.color"
    :outlined="false"
    style="max-width: 800px;"
    height="100%" >
    <v-row justify="space-between">
      <v-col xs="8" >
        <v-card-title>
          <div>
            <div class="highlight--text text--lighten-1 text-h4 font-weight-medium ">
              {{item.title}}
                <v-btn v-if="item.description==''"
                color="transparent"
                class="transparent cardtext--text rounded-lg elevation-0"
                :outlined="false"
                :to="item.link">conoce más...
                  <v-icon large>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
            </div>
          </div>
        </v-card-title>
      </v-col>
      <v-img
      v-if="item.conthumbnail"
        class="shrink ma-2"
        contain
        height="125px"
        :src="item.image"
        style="flex-basis: 125px">
      </v-img>
    </v-row>
    <v-divider dark></v-divider>
    <v-card-actions class="t pa-4">
      <v-container >
        <v-row>
          <v-col>
<div class="cardtext--text text-h6  "
        v-html="item.subtitle">
        </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div v-if="item.description !=''"
            v-html="item.description"></div>
          </v-col>
        </v-row>
      </v-container>

        <v-divider dark></v-divider>

    </v-card-actions>
  </v-card>

</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.transparent-button {
  background: transparent
}
</style>
