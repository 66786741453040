<template>
  <div id="consultoria-main">
    <v-container fluid class="mb-10">
      <v-row
      align="center"
      justify="center">
        <v-col sm="12" md="12" lg="6" xl="6" >
            <v-container fluid >
                <v-row
                align="center"
                justify="center">
                    <v-col cols="12"
                    class="mt-15 lighten-2 fill-height
                    d-flex flex-column justify-center align-center">
                        <div flat tile>
                            <card-item-info
                            :item="consultoria">
                            </card-item-info>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col sm="12" md="12" lg="6" xl="6">
          <v-container fluid  >
            <v-row align="center" justify="center">
              <v-col
              height="100%"
              sm="12" md="12" lg="6" xl="6"
              v-for="(item,i) in imagesConsultoria"
              :key="i">
                <v-img
                  class="shrink ma-2"
                  contain
                  :src="item.src"
                  style="flex-basis: 125px">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardItemInfo from '../components/elementos/CardItemInfo.vue';

export default {
  name: 'ConsultoriaMain',
  components: {
    CardItemInfo,
  },
  data() {
    return {
      imagesConsultoria: [
        {
          src: 'consultoria.jpg',
          title: '',
        },
      ],
      consultoria:
        {
          title: 'Consultoría',
          subtitle: `<p>Consulta y asesoría especializada en una amplia 
                          gama de temas centrales para las organizaciones 
                          públicas y privadas, mediante el desarrollo 
                          de software a la medida</p>`,
          color: 'cardbkg ',
          image: 'consultoria.jpg',
          description: `
            <ul>
                <li>Sin importar cual sea su necesidad, estamos seguros de poder desarrollar 
                la solución más óptima haciendo uso de lo mejor que ofrecen la tecnología actual</li>
                <li>Nos especializamos en estudiar y comprender sus procesos, 
                sugerir innovaciones y entregar soluciones de software prácticas y seguras 
                que mejorán el desempeño y resultados de su organización</li>
            </ul>
            <br>

            <br><br>`,
          conthumbnail: false,
        },
    };
  }
};
</script>
