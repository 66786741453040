<template>
  <v-app id="app">
    <!-- Barra de navegacion -->
    <nav-bar class="hidden-md-and-up" ></nav-bar>
    <menu-line class="hidden-sm-and-down"></menu-line>

    <!-- Barra de noticia -->
    <banner-noticia
    class="hidden-md-and-up"
    :mensaje="noticia.menssage"
    :icon="noticia.icon"
    ></banner-noticia>

    <!-- Contenedor para paginas de rutas de navegacion -->
    <div >
      <router-view />
    </div>

    <!-- Pie de pagina -->
    <footer-principal>
    </footer-principal>
  </v-app>
</template>

<script>
import BannerNoticia from './components/BannerNoticia.vue';
import FooterPrincipal from './components/FooterPrincipal.vue';
import NavBar from './components/NavBar.vue';
import MenuLine from './components/MenuLine.vue';

export default {
  name: 'App',
  components: {
    BannerNoticia,
    FooterPrincipal,
    NavBar,
    MenuLine,

  },

  data: () => ({
    noticia: {
      menssage: 'Conoce el nuevo SIDEOP v2.0',
      icon: 'mdi-check-decagram-outline',
    },
  }),
};
</script>

<style>
#app{
  background-color: #F2F6FF;
}
</style>
