<template>
  <div id="ecompras-main">
    <v-container fluid class="mb-10" >
      <v-row
      align="center"
      justify="center">
        <v-col sm="12" md="12" lg="6" xl="6" >
            <v-container fluid >
                <v-row
                align="center"
                justify="center">
                    <v-col cols="12"
                    class="mt-15 lighten-2 fill-height
                    d-flex flex-column justify-center align-center">
                        <div flat tile
                        v-for="(item,i) in productos"
                        :key="i">
                            <card-item-info
                            :item="item">
                            </card-item-info>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col sm="12" md="12" lg="6" xl="6">
          <v-container fluid  >
            <v-row align="center" justify="center">
              <v-col
              height="100%"
              sm="12" md="12" lg="6" xl="6"
              v-for="(item,i) in imagesProducto"
              :key="i">
                <v-img
                  class="shrink ma-2"
                  contain
                  :src="item.src"
                  style="flex-basis: 125px">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardItemInfo from '../components/elementos/CardItemInfo.vue';

export default {
  name: 'EComprasMain',
  components: {
    CardItemInfo,
  },
  data() {
    return {
      imagesProducto: [
        {
          src: 'compras.jpg',
          title: '',
        },
      ],
      productos: [
        {
          title: 'eCompras',
          subtitle: `<p>Plataforma Web para la adquisición de productos 
                y servicios en un esquema abierto 
                de participación de proveedores</p>`,
          color: 'cardbkg ',
          image: 'compras.jpg',
          description: `
           <ul>
                <li>Registro de proveedores por tipo de bien y servicio</li>
                <li>Integración de paquetes de bienes y servicios a adquirir</li>
                <li>Temporizador para la recepción de cotizaciones</li>
                <li>Comparativos de propuestas por proveedor y partida</li>
                <li>Integración de Ordenes de Compras</li>
                <li>Reportes y Estadísticas</li>
            </ul>
            <br>
            <h3>Principales ventajas</h3>
            <ul>
                <li>Optimización de recursos disponibles gracias a
                los significativos ahorros generados.</li>
                <li>Facilita la organización de cotizaciones por proveedor y partida</li>
                <li>Trabajo remoto de unidad compradora y proveedores</li>
            </ul>
            <br><br>`,
          conthumbnail: false,
        }],
    };
  },
};
</script>
<style>

</style>
