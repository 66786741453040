<template>
  <div id="principal-main">

    <!-- Imagen del inicio de la view -->
    <div id="barra" >
      <v-parallax :src="imagenBarra"></v-parallax>
    </div>
    <!-- Contenedor de los renglones de textos -->
    <v-container >
      <v-row>
        <v-col>
          <text-page
          class="py-4"
          :texto="textos.uno">
          </text-page>
        </v-col>
      </v-row>
      <v-row >
        <!-- Ciclo de columnas de las Cards -->
        <v-col sm="12" md="6" lg="4" xl="4"
        v-for="item in opciones"
        :key="item.title" >
          <!-- Cards que se despliegan -->
          <card-item
          :image="item.imageLink"
          :title="item.title"
          :textDescription="item.textDescription" >
          </card-item>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-page
          class="pb-2"
          :texto="textos.dos">
          </text-page>
        </v-col>
      </v-row>
      <v-row>
        <!-- Imagen de la ubicación -->
        <v-col xs="12" md="6" lg="6">
          <div class="pt-3 " height="100%" >
              <v-card
              flat
              class="text-center d-flex
              flex-column align-center justify-center"
              height="100%">
                <v-img
                  src="ubicacion.png" >
                </v-img>
              </v-card>
          </div>
        </v-col>
        <!-- Texto comunicacion -->
        <v-col xs="12" md="6" lg="6">
          <div class="pt-3 " height="100%" >
            <v-card
            flat
            class="bkground text-center d-flex
            flex-column align-center justify-center"
            height="100%">
              <v-avatar
              style="circle"
              class="highlight lighten-4"
              height="80"
              width="80">
                <v-icon
                size="50"
                class="highlight--text">
                mdi-cellphone-information
                </v-icon>
              </v-avatar>
              <text-page
              :texto="textos.tres">
              </text-page>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <!-- Forma de contacto -->
      <v-row>
        <v-col xs="12" sm="12" md="6" lg="6"  >
          <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          color="error"
          class="error--text pa-5">
            <v-text-field
            v-model="nombre"
            :rules="nombreRules"
            label="Nombre"
            required >
            </v-text-field>

            <v-text-field
            v-model="correo"
            :rules="correoRules"
            label="Correo Electrónico"
            required >
            </v-text-field>

            <v-text-field
            v-model="asunto"
            v-show="false">
            </v-text-field>

            <v-textarea
            name="input-7-1"
            v-model="mensaje"
            label="Consulta o comentario"
            :value="mensaje"
            hint="Estamos para apoyarlo">
            </v-textarea>

            <div class=" justify-right">
              <v-btn style="boton"
                :disabled="!valid"
                color="success"
                class="mr-4 "
                @click="validate" >
                  Enviar
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import CardItem from '../components/elementos/CardItem.vue';
import TextPage from '../components/elementos/TextPage.vue';

export default {
  name: 'PrincipalMain',
  data() {
    return {
      imagenBarra: 'banner-principal.png',
      textos: {
        uno: `Somos una compañía establecida en el Estado de Sonora
              con mas de una década de ofrecer soluciones de software
              y servicios de consultoría especializados que fortalecen
              el cumplimiento de los objetivos estratégicos y operativos
              de nuestros clientes, minimizando al máximo sus riesgos
              de incumplir con los requisitos legales.`,
        dos: `Utilizamos las herramientas de desarrollo mas
              actualizadas del mercado en los proyectos de
              nuestros clientes y priorizamos la creatividad
              e innovación en nuestro trabajo.`,
        tres: `Comparta la información de su proyecto con nosotros 
            y podemos agendar una primera
            reunión de diagnóstico sin costo ni
            compromiso para usted`,
      },
      opciones: [{
        imageLink: 'graficas.jpg',
        title: 'Productos',
        textDescription: `Soluciones de software en las áreas de control 
                          de obra pública, control y digitalización de archivos, 
                          adquisición de productos y servicios en esquemas abiertos `,
      },
      {
        imageLink: 'servicios.jpg',
        title: 'Servicios',
        textDescription: `Prestación de servicios profesionales que contribuyen 
                          al cumplimiento de los objetivos de su organización`,
      },
      {
        imageLink: 'consultoria.jpg',
        title: 'Consultoría',
        textDescription: `Consulta y asesoría especializada en una amplia 
                          gama de temas centrales para las organizaciones 
                          públicas y privadas, mediante el desarrollo 
                          de software a la medida`,
      }],
      valid: false,
      nombre: '',
      nombreRules: [
        (v) => !!v || 'Nombre es requerido',
        // (v) => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      correo: '',
      correoRules: [
        (v) => !!v || 'Correo electrónico es requerido',
        (v) => /.+@.+\..+/.test(v) || 'Correo electrónico inválido',
      ],
      asunto: '',
      mensaje: '',
    };
  },
  components: {
    CardItem,
    TextPage,

  },
  methods: {
    async validate() {
      this.$refs.form.validate();
      if (this.nombre !== '' && this.correo !== '') {
        await this.axios.post(
          'http://localhost:3031/enviarcorreo',
          {
            asunto: 'Forma de Contacto ISNC - ',
            mensaje: 'Mensaje prueba',
            correo: 'almaacevedo@hotmail.com',
          },

        )
          .then((res) => {
            console.log('res--', res);
          })
          .catch((err) => {
            console.log('err', err.data.message);
          });
      }
    },
  },
  computed: {

  },
};
</script>
<style>
.boton{
  border: unset;
  cursor: pointer;
  box-shadow: unset;
}
</style>
