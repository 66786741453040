import Vue from 'vue';
import VueRouter from 'vue-router';
// import HelloWorld from '../components/HelloWorld.vue';
import ProductosMain from '../views/productosMain.vue';
import PrincipalMain from '../views/principalMain.vue';
import ServiciosMain from '../views/serviciosMain.vue';
import ConsultoriaMain from '../views/consultoriaMain.vue';
import SideopMain from '../views/sideopMain.vue';
import TuArchivoMain from '../views/tuArchivoMain.vue';
import EComprasMain from '../views/eComprasMain.vue';
import OsdMain from '../views/osdMain.vue';
import PropuestasMain from '../views/propuestasMain.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'principal',
    component: PrincipalMain,
  },
  {
    path: '/productos',
    name: 'productos',
    component: ProductosMain,
  },
  {
    path: '/consultoria',
    name: 'consultoria',
    component: ConsultoriaMain,
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: ServiciosMain,
  },
  {
    path: '/sideop',
    name: 'sideop',
    component: SideopMain,
  },
  {
    path: '/tuarchivo',
    name: 'tuarchivo',
    component: TuArchivoMain,
  },
  {
    path: '/ecompras',
    name: 'ecompras',
    component: EComprasMain,
  },
  {
    path: '/propuestas',
    name: 'propuestas',
    component: PropuestasMain,
  },
  {
    path: '/osd',
    name: 'osd',
    component: OsdMain,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
