<template>
  <div id="servicios-main">
    <v-container fluid class="mb-10">
      <v-row
      align="center"
      justify="center">
        <v-col sm="12" md="12" lg="6" xl="6" >
            <v-container fluid >
                <v-row
                align="center"
                justify="center">
                    <v-col cols="12"
                    class="mt-15 lighten-2 fill-height
                    d-flex flex-column justify-center align-center">
                        <div flat tile>
                            <card-item-info
                            :item="servicios">
                            </card-item-info>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-col>
        <v-col sm="12" md="12" lg="6" xl="6">
          <v-container fluid  >
            <v-row align="center" justify="center">
              <v-col
              height="100%"
              sm="12" md="12" lg="6" xl="6"
              v-for="(item,i) in imagesServicios"
              :key="i">
                <v-img
                  class="shrink ma-2"
                  contain
                  :src="item.src"
                  style="flex-basis: 125px">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CardItemInfo from '../components/elementos/CardItemInfo.vue';

export default {
  name: 'ServiciosMain',
  components: {
    CardItemInfo,
  },
  data() {
    return {
      imagesServicios: [
        {
          src: 'servicios.jpg',
          title: '',
        },
      ],
      servicios:
        {
          title: 'Servicios',
          subtitle: `<p>Prestación de servicios profesionales que contribuyen 
                          al cumplimiento de los objetivos de su organización</p>`,
          color: 'cardbkg ',
          image: 'servicios.jpg',
          description: `
            <ul>
                <li>Control de la Ejecución de Obras Públicas</li>
                <li>Compras Abiertas y Licitaciones</li>
                <li>Digitalización de Archivos</li>
                <li>Transparencia Focalizada</li>
                <li>Esquemas de Participación Ciudadana</li>
                <li>Automatización y seguimiento de procesos operativos</li>
                <li>Control Interno y Administración de Riesgos</li>
            </ul>
            <br>

            <br><br>`,
          conthumbnail: false,
        },
    };
  }
};
</script>
