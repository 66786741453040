<template>
  <v-footer
  app
  height="auto"
  color="barmenu" >
    <v-layout
    justify-center
    align-center
    row
    wrap>
      <v-flex
      py-3
      text-xs-center
      highlight--text
      text--lighten-5 xs12>
        &copy;2023 —
        <strong>ISNCompany</strong>
      </v-flex>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: 'FooterPrincipal',
};
</script>
