<template>
    <div
    id="text-page">
        <h2 class="textcolor--text font-weight-light">
              {{texto}}
        </h2>
    </div>
</template>
<script>
export default {
  name: 'TextPage',
  props: {
    texto: {
      type: String,
      required: false,
      default: 'text',
    },
  },
};
</script>
