import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {

        primary: '#32396b',
        secondary: '#1B1BAF',
        barmenu: '#17195f',
        cardbkg: '#E9E9F4',
        cardtext: '#32396b',
        noticiabkg: '#32396b',
        highlight: '#3c6fa9',
        highlightfondo: '#9C27B0',
        textcolor: '#17195f',
        bkground: '#F2F6FF',
        error: '#dd870a',
        success: '#43BC82',

      },
    },
  },

  icons: {
    iconfont: 'mdi',
    values: {
      apps: 'mdi-apps',
      servicios: 'mdi-cube-unfolded',
    },
  },

});
