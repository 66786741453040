<template>
  <div id="nav-bar">

    <!-- Barra de navegacion -->
    <v-app-bar
    app
    color="barmenu"
    flat
    height="100px" >
      <div class="d-flex align-center">
        <!-- Ruta del home en el logo -->
        <router-link to="/">
          <v-img
            alt="ISNC"
            class="shrink mr-2"
            contain
            src="../../public/isnc-logo.png"
            transition="scale-transition"
            width="150px"
            to="/productos" />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon x-large
        color="highlight lighten-4"
        @click.stop="drawer = !drawer"
        class="" >
      </v-app-bar-nav-icon>
    </v-app-bar>

    <!-- Opciones Barra de navegacion -->
    <v-navigation-drawer
    app
    flat
    dark
    v-model="drawer"
    width="250"
    color="barmenu" >
    <v-list
    nav
    dense >
      <v-list-item v-for="item in options" :key="item.id">
        <v-list-item-icon>
          <v-icon
          x-large
          color="highlight lighten-4" >
          {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <router-link
            :to="item.link"
            class="text-h6 font-weight-bold"
            style="text-decoration: none; color: inherit; ">
              {{item.name}}
            </router-link>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

  </div>
</template>

<script>

export default {
  name: 'NavBar',
  data: () => ({
    noticia: {
      menssage: 'Conoce SIDEOP v2.0, la mejor herramienta para el control de obra pública',
      icon: 'mdi-check-decagram-outline',
    },
    drawer: false,
    options: [
      {
        id: 'productos',
        name: 'Productos',
        link: '/productos',
        icon: 'mdi-apps',
      },
      {
        id: 'servicios',
        name: 'Servicios',
        link: '/servicios',
        icon: 'mdi-progress-wrench',
      },
      {
        id: 'consultoria',
        name: 'Consultoría',
        link: '/consultoria',
        icon: 'mdi-gesture-tap-hold',
      },

    ],
  }),

};
</script>
